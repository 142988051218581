import React, { createContext, useReducer } from 'react'

const Reducer = (state, action) => {
  switch (action.type) {
    case 'SET_LOGGED_IN':
      return {
        ...state,
        loggedIn: action.payload,
      }
    default:
      return state
  }
}

const getLoggedIn = () => {
  if (localStorage.getItem('authToken')) {
    return true
  } else {
    return false
  }
}

const initialState = {
  loggedIn: getLoggedIn()
}

export const GlobalStore = ({ children }) => {
  const [globalState, dispatch] = useReducer(Reducer, initialState)

  return (
    <globalContext.Provider value={{ globalState, dispatch }}>
      {children}
    </globalContext.Provider>
  )
}

export const globalContext = createContext()
