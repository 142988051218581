import Topbar from './Components/Topbar'
import Routes from './Routes'
import { GlobalStore } from './GlobalStore.js'

const App = () => {
  return (
    <GlobalStore>
      <div id='main'>
        <Topbar />
        <Routes />
      </div>
    </GlobalStore>
  )
}

export default App
