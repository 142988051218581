import loadable from '@loadable/component'
import React from "react"
import { Route, Router, Switch } from "react-router-dom"
import history from './history'

const Index = loadable(() => import('./Pages/Index'))
const Overview = loadable(() => import('./Pages/Overview'))
const OverviewIncome = loadable(() => import('./Pages/OverviewIncome'))
const AddTxn = loadable(() => import('./Pages/AddTxn'))
const AddIncome = loadable(() => import('./Pages/AddIncome'))
const AccMgmt = loadable(() => import('./Pages/AccMgmt'))

const Routes = (props) => {
  return (
    <Router history={history}>
      <Switch>
        <Route
          exact
          path="/"
          render={(passProps) => (
            <Index {...passProps} />
          )}
        />
        <Route
          exact
          path="/Overview"
          render={(passProps) => (
            <Overview {...passProps} />
          )}
        />
        <Route
          exact
          path="/OverviewIncome"
          render={(passProps) => (
            <OverviewIncome {...passProps} />
          )}
        />
        <Route
          exact
          path="/AddTransaction"
          render={(passProps) => (
            <AddTxn {...passProps} />
          )}
        />
        <Route
          exact
          path="/AddIncome"
          render={(passProps) => (
            <AddIncome {...passProps} />
          )}
        />
        <Route
          exact
          path="/AccountManagement"
          render={(passProps) => (
            <AccMgmt {...passProps} />
          )}
        />
      </Switch>
    </Router>
  )
}

export default Routes
