import React, { useContext } from 'react'
import { GoogleLogout } from 'react-google-login'
import { globalContext } from '../GlobalStore.js'
import history from '../history'

const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID

function Logout() {
  const dispatch = useContext(globalContext).dispatch

  const onSuccess = () => {
    localStorage.clear()
    dispatch({ type: 'SET_LOGGED_IN', payload: false })
    history.push('/')
  }

  return (
    <div>
      <GoogleLogout
        clientId={clientId}
        render={renderProps => (
          <button onClick={renderProps.onClick}>Logout</button>
        )}
        onLogoutSuccess={onSuccess}
      ></GoogleLogout>
    </div>
  )
}

export default Logout
