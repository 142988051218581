import React, { useContext, useEffect, useState } from 'react'
import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import NavDropdown from 'react-bootstrap/NavDropdown'
import { globalContext } from '../GlobalStore.js'
import Logout from './Logout'

const getLoggedIn = () => {
  if (localStorage.getItem('authToken')) {
    return true
  } else {
    return false
  }
}

const Topbar = () => {
  const globalState = useContext(globalContext).globalState

  const [loggedIn, setLoggedIn] = useState(getLoggedIn)
  const [navActive, setNavActive] = useState(0)
  const [txnActive, setTxnActive] = useState(false)

  const changeNav = (pathname) => {
    if (pathname === "/" || pathname === "/Overview") {
      setNavActive(0)
    } 
    if (pathname === "/OverviewIncome") {
      setNavActive(1)
    } else if (pathname === "/AddTransaction") {
      setNavActive(2.1)
      setTxnActive(true)
    }else if (pathname === "/AddIncome") {
      setNavActive(2.2)
      setTxnActive(true)
    } else if (pathname === "/AccountManagement") {
      setNavActive(2.3)
      setTxnActive(true)
    }
  }

  useEffect(() => {
    setLoggedIn(globalState.loggedIn)
    changeNav(window.location.pathname)
  }, [globalState.loggedIn])

  return (
    <Navbar bg="dark" variant='dark'>
      <Container>
        {loggedIn ?
          <>
            <Navbar.Brand href="/">
              <img
                alt=""
                src="/logo.png"
                width="auto"
                height="30"
                className="d-inline-block align-top"
              />
            </Navbar.Brand>
            <Navbar.Toggle />

            <Navbar.Collapse>
              <Nav activeKey={navActive} onSelect={() => changeNav(window.location.pathname)}>
                <Nav.Item>
                  <Nav.Link href="/" eventKey={0}>Savings</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link href="/OverviewIncome" eventKey={1}>Income</Nav.Link>
                </Nav.Item>
                <NavDropdown title="Transactions" menuVariant='dark' active={txnActive}>
                  <NavDropdown.Item href="/AddTransaction" eventKey="2.1">Add Savings</NavDropdown.Item>
                  <NavDropdown.Item href="/AddIncome" eventKey="2.2">Add Income</NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="/AccountManagement" eventKey="2.3">Savings Accounts</NavDropdown.Item>
                </NavDropdown>
              </Nav>
            </Navbar.Collapse>
            <Navbar.Collapse className="justify-content-end">
              <Navbar.Text>
                {localStorage.getItem('username')}
              </Navbar.Text>
              &nbsp;
              &nbsp;
              &nbsp;
              <Logout />
            </Navbar.Collapse>
          </>
          :
          null
        }
      </Container>
    </Navbar>
  )
}

export default Topbar